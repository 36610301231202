import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
			 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6044.61628625714!2d-73.94134042402783!3d40.755246871387094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258d3096ced9b%3A0x7bd5a821a1da44bb!2s39-16%2023rd%20St%2C%20Long%20Island%20City%2C%20NY%2011101!5e0!3m2!1sen!2sus!4v1723689400117!5m2!1sen!2sus"
          width="100%"
          height="100%"
        ></iframe>
		</div>
        }
}

export default Map