import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

class CopyRight extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		const currentYear = new Date().getFullYear();
		return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
				<div className="container-fluid ltn__border-top-2">
					<div className="row">
						<div className="col-md-4 col-12">
							<div className="ltn__copyright-design clearfix">
								<p>All Rights Reserved @ Interboro Signs & Electric Inc. <span className="current-year" /></p>
							</div>
						</div>
						<div className="col-md-8 col-12 align-self-center">
							<div className="ltn__copyright-menu text-end">
								<ul className="go-top">

									<li>
										<Link href="mailto:cindy@interborosigns.com">
											<FaEnvelope />
											<i></i> info@interborosigns.com
										</Link>
									</li>
									<li>
										<Link href="tel:718-366-7901">
											<FaPhoneAlt />
											<i></i> 718-366-7901
										</Link>
									</li>
									<li>
										<Link href="#">
											<FaMapMarkerAlt />
											39-16 23rd Street, Long Island City NY 11101
										</Link>
									</li>
									<li>
										<Link href="#">
											<FaMapMarkerAlt />
											1501 Broadway, Suite 2009, New York, NY 10036
										</Link>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default CopyRight