import React, { useEffect, useState } from 'react';
import { GoogleReviewsComponent } from 'google-reviews';  // Named import
import axios from 'axios';

const MyReviews = () => {
    function getGoogleReviews() {
        console.log('=> Fetching reviews data..')
        return new Promise((resolve, reject) => {
          let reviews;
          const url = 'https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJbdsyOMtZwokR9908BMEzqmM&key=AIzaSyBRLR8xVUCCTPVt4sEXNxqHzzEW1PnoFcE';
          axios.get(url)
          .then(res => {
            console.log('=> Reviews data fetched!')
            console.log(res.data)
            if(res.data.status == 'OK') {
              console.log('=> Saving new reviews to markdown..')
              let today = new Date('now');
              //reviews = res.data.result.reviews
      
            }
            resolve(reviews)
          })
        })
      }

      getGoogleReviews();
  return (
    <div>
      <h2>Google Reviews</h2>
      {/* {reviews.length > 0 ? (
        <ul>
          {reviews.map((review, index) => (
            <li key={index}>
              <strong>{review.author_name}</strong> - {review.rating} stars
              <p>{review.text}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No reviews available.</p>
      )} */}
    </div>
  );
};

export default MyReviews;
