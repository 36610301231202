import React, { Component,useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import emailjs from 'emailjs-com';
import {
  FaDribbble,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaCheck,
  FaCalendarAlt,
  FaUserAlt,
  FaEnvelope,
  FaGlobe,
  FaPencilAlt,
  FaBuilding,
  FaPhoneAlt,
  FaArrowDown,
} from "react-icons/fa";

const ContactForm = () => {



    // 	const $ = window.$;
    	
    //    	// Get the form.
	// var form = $('#contact-form');

	// // Get the messages div.
	// var formMessages = $('.form-messege');

	// // Set up an event listener for the contact form.
	// $(form).submit(function(e) {
	// 	// Stop the browser from submitting the form.
	// 	e.preventDefault();

	// 	// Serialize the form data.
	// 	var formData = $(form).serialize();

	// 	// Submit the form using AJAX.
	// 	$.ajax({
	// 		type: 'POST',
	// 		url: $(form).attr('action'),
	// 		data: formData
	// 	})
	// 	.done(function(response) {
	// 		// Make sure that the formMessages div has the 'success' class.
	// 		$(formMessages).removeClass('error');
	// 		$(formMessages).addClass('success');

	// 		// Set the message text.
	// 		$(formMessages).text(response);

	// 		// Clear the form.
	// 		$('#contact-form input,#contact-form textarea').val('');
	// 	})
	// 	.fail(function(data) {
	// 		// Make sure that the formMessages div has the 'error' class.
	// 		$(formMessages).removeClass('success');
	// 		$(formMessages).addClass('error');

	// 		// Set the message text.
	// 		if (data.responseText !== '') {
	// 			$(formMessages).text(data.responseText);
	// 		} else {
	// 			$(formMessages).text('Oops! An error occured and your message could not be sent.');
	// 		}
	// 	});
	// });

	const serviceID = "service_yw6iepq";
	const templateID = "template_kzusacf";
	const publicKey = "p7a5c7UV29u_I5kFD";
  
	const [formData, setFormData] = useState({
	  name: '',
	  email: '',
	  phone: '',
	  company: '',
	  message: '',
	});
	const [status, setStatus] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
  
	const handleChange = (e) => {
	  setFormData({ ...formData, [e.target.name]: e.target.value });
	};
  
   
  
	const handleSubmit = (e) => {
	  e.preventDefault();
	 setIsSubmitting(true); // Disable button on submit
	  emailjs.send(
		serviceID,
		templateID,
		formData,
		publicKey
	  ).then((result) => {
		setStatus("Thank you for contacting us, we'll get back to you soon!");
		setFormData({
		  name: '',
		  email: '',
		  phone: '',
		  company: '',
		  message: ''
		});
		setIsSubmitting(false); // Disable button on submit
	  }, (error) => {
		setStatus('Failed to send email.');
	  });
	};

    



	let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-message-area mb-120 ">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">Get A Quote</h4>
						{/* <form id="contact-form" action={publicUrl+"mail.php"} method="post"> */}
						<form id="contact-form" action="#" method="post" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <span className="inline-icon">
                          {/* <FaUserAlt /> */}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-email ltn__custom-icon">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <span className="inline-icon">
                          {/* <FaEnvelope /> */}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-phone ltn__custom-icon">
                        <input
                          type="text"
                          name="company"
                          placeholder="Enter Company Name"

                          value={formData.company}
                          onChange={handleChange}

                        />
                        <span className="inline-icon">
                          {/* <FaBuilding /> */}
                        </span>
                      </div>
                      {/* <div className="input-item input-item input-item-email ltn__custom-icon">
                        <Form.Select className="nice-select">
                          <option>Select Service Type</option>
                          <option>General Inquiry</option>
                          <option>Sign Installation</option>
                        </Form.Select>
                        <span className="inline-icon">
                          <FaArrowDown />
                        </span>
                      </div> */}
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-phone ltn__custom-icon">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Enter phone number"
                          value={formData.phone}
                          onChange={handleChange}
                          
                        />
                        <span className="inline-icon">
                          {/* <FaPhoneAlt /> */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-item input-item-textarea ltn__custom-icon">
                    <textarea
                      name="message"
                      placeholder="Enter message"

                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <span className="inline-icon">
                      {/* <FaPencilAlt /> */}
                    </span>
                  </div>
                  {/* <p>
                    <label className="input-info-save mb-0">
                      <input type="checkbox" name="agree" /> Save my name,
                      email, and website in this browser for the next time I
                      comment.
                    </label>
                  </p> */}
                  <div className="btn-wrapper mt-0">
                    <button
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      type="submit"
                      id="submitBtn"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Sending Email...' : 'Submit'}
                    </button>
                    <br></br>
                    
                  </div>
                  <p className="form-messege mb-0 mt-20" >
                  <h6 className='success'>{status}</h6>
                  </p>
                </form>
					</div>
					</div>
				</div>
				</div>
			</div>
        
}

export default ContactForm