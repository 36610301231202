import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

const CategoryV1 = ({data}) => {


	return (


		<div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115" id="serviceSection">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
							<h1 className="section-title">Our Main Focus</h1>
						</div>
					</div>
				</div>
				<div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center" >
					{data.map((item, index) => {
						return <div className="col-lg-3 col-md-4 col-sm-6 col-6">
							<div className="ltn__category-item ltn__category-item-5 text-center">
								<Link to={`/service-details/${item.name}`}>
									{/* <span className="category-icon"><i className="flaticon-car" /></span> */}
									<span className="category-title">{item.title}</span>
									<span className="category-btn"><i className="flaticon-right-arrow" /></span>
								</Link>
							</div>
						</div>
					})
					}


				</div>
			</div>
		</div>
	);
};
export default CategoryV1