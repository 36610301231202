import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Use the hash to find the target section
      setTimeout(() => {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); 
    }
  }, [location]);

  return null; // This component doesn't need to render anything
};

export default ScrollToSection;
