import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120 pb-90 ">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/others/home-about1.jpg"} alt="About Us Image" />
							<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								{/* <div className="ltn__video-img ltn__animation-pulse1">
									<img src={publicUrl + "assets/img/others/8.png"} alt="video popup bg image" />
									<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
										<i className="fa fa-play" />
									</a>
								</div> */}
							</div>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
								<h1 className="section-title">One of the Leading Sign and Electrical Maintenance Companies in NYC<span>.</span></h1>
								<p>
									INTERBORO Signs & Electric Inc. is a full service installation company dedicated solely to serving the sign, outdoor advertising and commercial light trades for over 35 years.
								</p>

								<p>
									We are fully equipped with a fleet of late model boom trucks, flatbeds and service vehicles with a reach capacity of 180 feet.
								</p>

								<p>
									We are also fully licensed and insured and can provide a certificate of insurance for every project. We have the latest technology to provide digital photo surveys of any size project and can also obtain permits upon request.


								</p>

								<p>
									Your valuable shipments can be received and stored in our secure warehouse for safe keeping until installation.
								</p>
							</div>
							<ul className="ltn__list-item-half clearfix">
								<li>
									<i className="flaticon-home-2" />
									Master Sign Hangers License
								</li>
								<li>
									<i className="flaticon-mountain" />
									Welding Licenses
								</li>
								<li>
									<i className="flaticon-heart" />
									Electrical License
								</li>
							
							</ul>
							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<p> Our employees are licensed boom truck operators and certified scaffolding operators. </p>
							</div>
							<div className="btn-wrapper animated">
								<Link to="/about#serviceSection" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV1