import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import serviceDetails from "../../data/serviceDetails.json";

const ServiceDetails = ({ service }) => {
	const [data, setData] = useState(null);
	let publicUrl = process.env.PUBLIC_URL + '/'

	// useEffect(() => {
	// 	// In this case, the JSON data is already imported statically
	// 	setData(serviceDetails);
	// 	console.log(serviceDetails)
	// }, []);

	// const service = data && data.find(item => item.slug === id);

	const firstLetter = service.shortDescription.slice(0, 2);
	const firstToEnd = service.shortDescription.slice(2);
	return (

		service.title === "Expert Sign Installation" || service.title === "Crane Rentals" || service.title === "Adhesive Vinyl Installations" || service.title === "Licensed Electricians" ?
			<div className="ltn__page-details-area ltn__service-details-area mb-105">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="ltn__page-details-inner ltn__service-details-inner">
								{/* <div className="ltn__blog-img">
						<img src={publicUrl+"assets/img/service/21.jpg"} alt="Image" />
						</div> */}
								{service.shortDescription !== "" && service.fullDescription !== "" &&
									<p> <span className="ltn__first-letter">{firstLetter}</span>{firstToEnd}

										<br></br>
										<p style={{ margin: '20px' }}><b style={{ marginTop: '10px' }}>{service.fullDescription}</b></p>
									</p>
								}
								<div className="row" style={{ margin: '0 40px' }}>
									<ul className="row">
										{service.bullets && service.bullets.map((bullet, index) => (
											<li style={{ width: '50%' }} key={index}>{bullet}</li>
										))}
									</ul>

								</div>
								<p>{service.captions.captionFullDescription}</p>
								<p>{service.captions.captionShortDescription}</p>
								{/* <div className="ltn__service-list-menu text-uppercase mt-50 ">
								<ul className="row">
									{service.bullets && service.bullets.map((bullet, index) => (
										<li style={{ width: '50%' }} key={index}>{bullet}</li>
									))}
								</ul>
							</div> */}
							</div>
						</div>
						{/* <div className="col-lg-4">
					<aside className="sidebar-area ltn__right-sidebar">
					
						<div className="widget-2 ltn__menu-widget ltn__menu-widget-2 text-uppercase">
						<ul className="go-top">
							<li><Link to="/service">Property Management <span><i className="fas fa-arrow-right" /></span></Link></li>
							<li className="active"><Link to="/service">Mortgage Service <span><i className="fas fa-arrow-right" /></span></Link></li>
							<li><Link to="/service">Consulting Service <span><i className="fas fa-arrow-right" /></span></Link></li>
							<li><Link to="/service">Home Buying <span><i className="fas fa-arrow-right" /></span></Link></li>
							<li><Link to="/service">Home Selling <span><i className="fas fa-arrow-right" /></span></Link></li>
							<li><Link to="/service">Escrow Services <span><i className="fas fa-arrow-right" /></span></Link></li>
						</ul>
						</div>
				
						<div className="widget ltn__search-widget ltn__newsletter-widget">                            
						<h6 className="ltn__widget-sub-title">// subscribe</h6>
						<h4 className="ltn__widget-title">Get Newsletter</h4>
						<form action="#">
							<input type="text" name="search" placeholder="Search" />
							<button type="submit"><i className="fas fa-search" /></button>
						</form>
						<div className="ltn__newsletter-bg-icon">
							<i className="fas fa-envelope-open-text" />
						</div>
						</div>
					
						<div className="widget ltn__banner-widget go-top">
						<Link to="/shop"><img src={publicUrl+"assets/img/banner/banner-1.jpg"} alt="Banner Image" /></Link>
						</div>
					</aside>
					</div> */}
					</div>
				</div>
			</div>
			: <p style={{ textAlign: 'center' }}>
				<img src={publicUrl+"assets/img/under-construction-2891888_1280.webp"} width="30%" />
			</p>

	);
};


export default ServiceDetails