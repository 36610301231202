import React, { useState, useEffect } from "react";
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ServiceDetails from './section-components/service-details';
import serviceInfo from "../data/serviceDetails.json";
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';

const Service_Details = ({ match }) => {
    const { id } = match.params;

    const [data, setData] = useState(null);
    let publicUrl = process.env.PUBLIC_URL + '/'

    useEffect(() => {
        // In this case, the JSON data is already imported statically
        setData(serviceInfo);
        console.log(serviceInfo)
    }, []);

    const service = data && data.find(item => item.slug === id);


    return (
        service && <div>
            <Helmet>
                <title>{`${service.title} - Interboro Signs &amp; Electric Inc.`}</title>
                <meta name="description" content={`Learn more about ${service.title} services`} />
                <meta property="og:title" content={`${service.title} - Interboro Signs &amp; Electric Inc.`} />
                <meta property="og:description" content={`Learn more about ${service.title} services`} />

                <script type="application/ld+json">
                    {JSON.stringify(data)}
                </script>
            </Helmet>
            <Navbar />
            <PageHeader headertitle="Service Details" subheader={service.title} />
            <ServiceDetails service={service} />
            {/* <CallToActionV1 /> */}
            <Footer />
        </div>
    )
}

export default Service_Details

